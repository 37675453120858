define("itarp-opportunity-designer/models/opportunity", ["exports", "@ember-data/model", "itarp-opportunity-designer/utils/constants/opportunity", "@ember/array", "moment", "@ember/template"], function (_exports, _model, _opportunity, _array, _moment, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _dec43, _dec44, _dec45, _dec46, _dec47, _dec48, _dec49, _dec50, _dec51, _dec52, _dec53, _dec54, _dec55, _dec56, _dec57, _dec58, _dec59, _dec60, _dec61, _dec62, _dec63, _dec64, _dec65, _dec66, _dec67, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39, _descriptor40, _descriptor41, _descriptor42, _descriptor43, _descriptor44, _descriptor45, _descriptor46, _descriptor47, _descriptor48, _descriptor49, _descriptor50, _descriptor51, _descriptor52, _descriptor53, _descriptor54, _descriptor55, _descriptor56, _descriptor57, _descriptor58, _descriptor59, _descriptor60, _descriptor61, _descriptor62, _descriptor63, _descriptor64, _descriptor65, _descriptor66, _descriptor67;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OpportunityModel = (_dec = (0, _model.attr)('date'), _dec2 = (0, _model.attr)('date'), _dec3 = (0, _model.attr)('date'), _dec4 = (0, _model.attr)('date'), _dec5 = (0, _model.attr)('date'), _dec6 = (0, _model.attr)('date'), _dec7 = (0, _model.attr)('date'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.attr)('string'), _dec18 = (0, _model.attr)('string'), _dec19 = (0, _model.attr)('image-url'), _dec20 = (0, _model.attr)('string'), _dec21 = (0, _model.attr)('string'), _dec22 = (0, _model.attr)('string'), _dec23 = (0, _model.attr)('string'), _dec24 = (0, _model.attr)('string'), _dec25 = (0, _model.attr)('string'), _dec26 = (0, _model.attr)('string'), _dec27 = (0, _model.attr)('string'), _dec28 = (0, _model.attr)('string'), _dec29 = (0, _model.attr)('string'), _dec30 = (0, _model.attr)('string'), _dec31 = (0, _model.attr)('string'), _dec32 = (0, _model.attr)('string'), _dec33 = (0, _model.attr)('string'), _dec34 = (0, _model.attr)('string'), _dec35 = (0, _model.attr)('string'), _dec36 = (0, _model.attr)('string'), _dec37 = (0, _model.attr)('boolean'), _dec38 = (0, _model.attr)('boolean'), _dec39 = (0, _model.attr)('boolean'), _dec40 = (0, _model.attr)('boolean'), _dec41 = (0, _model.attr)('boolean'), _dec42 = (0, _model.attr)('boolean'), _dec43 = (0, _model.attr)('boolean'), _dec44 = (0, _model.attr)('number'), _dec45 = (0, _model.attr)('number'), _dec46 = (0, _model.attr)('number'), _dec47 = (0, _model.attr)('number', {
    defaultValue: 40
  }), _dec48 = (0, _model.attr)('number', {
    defaultValue: 1
  }), _dec49 = (0, _model.attr)('string', {
    defaultValue: _opportunity.INITIAL_STATUS
  }), _dec50 = (0, _model.attr)({
    defaultValue: () => (0, _array.A)([])
  }), _dec51 = (0, _model.attr)({
    defaultValue: () => (0, _array.A)([])
  }), _dec52 = (0, _model.attr)({
    defaultValue: () => (0, _array.A)([])
  }), _dec53 = (0, _model.attr)({
    defaultValue: () => (0, _array.A)([])
  }), _dec54 = (0, _model.attr)({
    defaultValue: () => (0, _array.A)([])
  }), _dec55 = (0, _model.attr)({
    defaultValue: () => (0, _array.A)([])
  }), _dec56 = (0, _model.attr)({
    defaultValue: () => (0, _array.A)([])
  }), _dec57 = (0, _model.attr)({
    defaultValue: () => (0, _array.A)([])
  }), _dec58 = (0, _model.attr)({
    defaultValue: () => (0, _array.A)([])
  }), _dec59 = (0, _model.attr)({
    defaultValue: () => (0, _array.A)([])
  }), _dec60 = (0, _model.attr)({
    defaultValue: () => (0, _array.A)([])
  }), _dec61 = (0, _model.attr)(), _dec62 = (0, _model.hasMany)('talent-cloud'), _dec63 = (0, _model.hasMany)('practice-area'), _dec64 = (0, _model.hasMany)('questionnaire'), _dec65 = (0, _model.attr)('boolean'), _dec66 = (0, _model.attr)('boolean'), _dec67 = (0, _model.attr)('boolean'), (_class = class OpportunityModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "endDate", _descriptor, this);

      _initializerDefineProperty(this, "startDate", _descriptor2, this);

      _initializerDefineProperty(this, "createdAt", _descriptor3, this);

      _initializerDefineProperty(this, "approvedAt", _descriptor4, this);

      _initializerDefineProperty(this, "onHoldDate", _descriptor5, this);

      _initializerDefineProperty(this, "declineDate", _descriptor6, this);

      _initializerDefineProperty(this, "closeDate", _descriptor7, this);

      _initializerDefineProperty(this, "approvedBy", _descriptor8, this);

      _initializerDefineProperty(this, "jobType", _descriptor9, this);

      _initializerDefineProperty(this, "cloneId", _descriptor10, this);

      _initializerDefineProperty(this, "jobTitle", _descriptor11, this);

      _initializerDefineProperty(this, "internalId", _descriptor12, this);

      _initializerDefineProperty(this, "workflowId", _descriptor13, this);

      _initializerDefineProperty(this, "workflowStageId", _descriptor14, this);

      _initializerDefineProperty(this, "workflowApprovalId", _descriptor15, this);

      _initializerDefineProperty(this, "workflowApprovalStageId", _descriptor16, this);

      _initializerDefineProperty(this, "description", _descriptor17, this);

      _initializerDefineProperty(this, "fileAttachment", _descriptor18, this);

      _initializerDefineProperty(this, "projectThumbnail", _descriptor19, this);

      _initializerDefineProperty(this, "hiringManager", _descriptor20, this);

      _initializerDefineProperty(this, "remunerations", _descriptor21, this);

      _initializerDefineProperty(this, "preferredCity", _descriptor22, this);

      _initializerDefineProperty(this, "preferredState", _descriptor23, this);

      _initializerDefineProperty(this, "preferredCountry", _descriptor24, this);

      _initializerDefineProperty(this, "preferredZipCode", _descriptor25, this);

      _initializerDefineProperty(this, "travelRequirement", _descriptor26, this);

      _initializerDefineProperty(this, "closeReasonMessage", _descriptor27, this);

      _initializerDefineProperty(this, "idealCandidateLocation", _descriptor28, this);

      _initializerDefineProperty(this, "onHoldMessage", _descriptor29, this);

      _initializerDefineProperty(this, "declineMessage", _descriptor30, this);

      _initializerDefineProperty(this, "page", _descriptor31, this);

      _initializerDefineProperty(this, "actionFlag", _descriptor32, this);

      _initializerDefineProperty(this, "importedFrom", _descriptor33, this);

      _initializerDefineProperty(this, "companyName", _descriptor34, this);

      _initializerDefineProperty(this, "companyDisplayName", _descriptor35, this);

      _initializerDefineProperty(this, "hiringManagerDisplayName", _descriptor36, this);

      _initializerDefineProperty(this, "flexible", _descriptor37, this);

      _initializerDefineProperty(this, "isPublic", _descriptor38, this);

      _initializerDefineProperty(this, "isProject", _descriptor39, this);

      _initializerDefineProperty(this, "officeHours", _descriptor40, this);

      _initializerDefineProperty(this, "openToRemote", _descriptor41, this);

      _initializerDefineProperty(this, "outsideOfficeHours", _descriptor42, this);

      _initializerDefineProperty(this, "selectedPreferredLocation", _descriptor43, this);

      _initializerDefineProperty(this, "minRate", _descriptor44, this);

      _initializerDefineProperty(this, "maxRate", _descriptor45, this);

      _initializerDefineProperty(this, "targetDaysToFill", _descriptor46, this);

      _initializerDefineProperty(this, "timeCommitment", _descriptor47, this);

      _initializerDefineProperty(this, "numberOfResources", _descriptor48, this);

      _initializerDefineProperty(this, "status", _descriptor49, this);

      _initializerDefineProperty(this, "skills", _descriptor50, this);

      _initializerDefineProperty(this, "tools", _descriptor51, this);

      _initializerDefineProperty(this, "industry", _descriptor52, this);

      _initializerDefineProperty(this, "questions", _descriptor53, this);

      _initializerDefineProperty(this, "educations", _descriptor54, this);

      _initializerDefineProperty(this, "closeReason", _descriptor55, this);

      _initializerDefineProperty(this, "onHoldReasons", _descriptor56, this);

      _initializerDefineProperty(this, "declineReasons", _descriptor57, this);

      _initializerDefineProperty(this, "skillLevels", _descriptor58, this);

      _initializerDefineProperty(this, "certifications", _descriptor59, this);

      _initializerDefineProperty(this, "scorecardIds", _descriptor60, this);

      _initializerDefineProperty(this, "locationCoordinates", _descriptor61, this);

      _initializerDefineProperty(this, "cloudId", _descriptor62, this);

      _initializerDefineProperty(this, "practiceAreas", _descriptor63, this);

      _initializerDefineProperty(this, "questionnaire", _descriptor64, this);

      _initializerDefineProperty(this, "isJobDetailsComplete", _descriptor65, this);

      _initializerDefineProperty(this, "isWorkArrangementComplete", _descriptor66, this);

      _initializerDefineProperty(this, "isWorkflowsComplete", _descriptor67, this);
    }

    get talentClouds() {
      return this.cloudId;
    }

    set talentClouds(value) {
      this.cloudId = value;
      return this.cloudId;
    }

    get fillByDate() {
      const {
        startDate,
        targetDaysToFill
      } = this;

      if (startDate && targetDaysToFill) {
        const date = new Date(startDate);
        date.setDate(date.getDate() + targetDaysToFill);
        return date.toLocaleDateString('en-US');
      }
    }

    get daysOpen() {
      const currentDate = _moment.default.utc(new Date()).toDate();

      if (this.approvedAt) {
        const approvedAt = _moment.default.utc(this.approvedAt).toDate();

        const difference = (0, _moment.default)(currentDate).diff(approvedAt, 'hours') / 24;

        const duration = _moment.default.duration(difference * -1, 'days').humanize();

        if (duration === 'a month') return '1 Month';
        if (duration === 'a day') return '1 Day';
        return duration;
      }
    }

    get duration() {
      const {
        startDate,
        endDate,
        jobType
      } = this;

      if (jobType === 'Contract' && startDate && endDate) {
        const start = _moment.default.utc(startDate).toDate();

        const end = _moment.default.utc(endDate).toDate();

        const difference = (0, _moment.default)(end).diff(start, 'months');

        const duration = _moment.default.duration(difference * -1, 'months').humanize();

        if (duration === 'a few seconds') return '1 Day';
        if (duration === 'a month') return '1 Month';
        return duration;
      }
    }

    get truncatedDescription() {
      const stripHtml = html => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || '';
      };

      const cleanedDescription = stripHtml(this.description);
      const truncated = cleanedDescription.length > 250 ? `${cleanedDescription.slice(0, 250)}...` : cleanedDescription;
      return (0, _template.htmlSafe)(truncated);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "endDate", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "startDate", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "approvedAt", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "onHoldDate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "declineDate", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "closeDate", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "approvedBy", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "jobType", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "cloneId", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "jobTitle", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "internalId", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "workflowId", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "workflowStageId", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "workflowApprovalId", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "workflowApprovalStageId", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "fileAttachment", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "projectThumbnail", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "hiringManager", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "remunerations", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "preferredCity", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "preferredState", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "preferredCountry", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "preferredZipCode", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "travelRequirement", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "closeReasonMessage", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "idealCandidateLocation", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "onHoldMessage", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "declineMessage", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "actionFlag", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "importedFrom", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "companyName", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "companyDisplayName", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "hiringManagerDisplayName", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "flexible", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = _applyDecoratedDescriptor(_class.prototype, "isPublic", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = _applyDecoratedDescriptor(_class.prototype, "isProject", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor40 = _applyDecoratedDescriptor(_class.prototype, "officeHours", [_dec40], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor41 = _applyDecoratedDescriptor(_class.prototype, "openToRemote", [_dec41], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor42 = _applyDecoratedDescriptor(_class.prototype, "outsideOfficeHours", [_dec42], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor43 = _applyDecoratedDescriptor(_class.prototype, "selectedPreferredLocation", [_dec43], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor44 = _applyDecoratedDescriptor(_class.prototype, "minRate", [_dec44], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor45 = _applyDecoratedDescriptor(_class.prototype, "maxRate", [_dec45], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor46 = _applyDecoratedDescriptor(_class.prototype, "targetDaysToFill", [_dec46], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor47 = _applyDecoratedDescriptor(_class.prototype, "timeCommitment", [_dec47], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor48 = _applyDecoratedDescriptor(_class.prototype, "numberOfResources", [_dec48], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor49 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec49], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor50 = _applyDecoratedDescriptor(_class.prototype, "skills", [_dec50], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor51 = _applyDecoratedDescriptor(_class.prototype, "tools", [_dec51], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor52 = _applyDecoratedDescriptor(_class.prototype, "industry", [_dec52], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor53 = _applyDecoratedDescriptor(_class.prototype, "questions", [_dec53], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor54 = _applyDecoratedDescriptor(_class.prototype, "educations", [_dec54], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor55 = _applyDecoratedDescriptor(_class.prototype, "closeReason", [_dec55], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor56 = _applyDecoratedDescriptor(_class.prototype, "onHoldReasons", [_dec56], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor57 = _applyDecoratedDescriptor(_class.prototype, "declineReasons", [_dec57], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor58 = _applyDecoratedDescriptor(_class.prototype, "skillLevels", [_dec58], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor59 = _applyDecoratedDescriptor(_class.prototype, "certifications", [_dec59], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor60 = _applyDecoratedDescriptor(_class.prototype, "scorecardIds", [_dec60], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor61 = _applyDecoratedDescriptor(_class.prototype, "locationCoordinates", [_dec61], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor62 = _applyDecoratedDescriptor(_class.prototype, "cloudId", [_dec62], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor63 = _applyDecoratedDescriptor(_class.prototype, "practiceAreas", [_dec63], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor64 = _applyDecoratedDescriptor(_class.prototype, "questionnaire", [_dec64], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor65 = _applyDecoratedDescriptor(_class.prototype, "isJobDetailsComplete", [_dec65], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor66 = _applyDecoratedDescriptor(_class.prototype, "isWorkArrangementComplete", [_dec66], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor67 = _applyDecoratedDescriptor(_class.prototype, "isWorkflowsComplete", [_dec67], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OpportunityModel;
});
define("itarp-components/components/ui/input/infinity-select", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/service", "@ember/array", "@ember/runloop"], function (_exports, _component, _templateFactory, _component2, _object, _tracking, _service, _array, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Ui::Wrapper @padding={{true}} ...attributes>
    <Ui::Input::SelectGroup @required={{@required}} as |selectGroup|>
      {{#let
        (component
          'power-select'
          optionsComponent='ui/input/infinity-multi-select/options'
          extra=(hash
            loadMore=this.loadMore
            searching=this.searchTerm
            allLoaded=this.allOptionsLoaded
          )
          searchField=@searchField
          onChange=this.onChange
          selected=this.selected
          options=this.options
          searchEnabled=true
          onOpen=this.onOpen
          search=this.search
          onInput=(fn (mut this.searchTerm))
          onClose=this.resetSearch
          noMatchesMessage=(if
            this.searchTerm
            (if this.loading 'Loading...' 'No results found')
            (if this.loading 'Loading...' 'No options available')
          )
        )
        as |select|
      }}
        {{yield (hash label=selectGroup.label select=select)}}
      {{/let}}
    </Ui::Input::SelectGroup>
  </Ui::Wrapper>
  
  */
  {
    "id": "dZnuNGCt",
    "block": "[[[8,[39,0],[[17,1]],[[\"@padding\"],[true]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@required\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n\"],[44,[[50,\"power-select\",0,null,[[\"optionsComponent\",\"extra\",\"searchField\",\"onChange\",\"selected\",\"options\",\"searchEnabled\",\"onOpen\",\"search\",\"onInput\",\"onClose\",\"noMatchesMessage\"],[\"ui/input/infinity-multi-select/options\",[28,[37,4],null,[[\"loadMore\",\"searching\",\"allLoaded\"],[[30,0,[\"loadMore\"]],[30,0,[\"searchTerm\"]],[30,0,[\"allOptionsLoaded\"]]]]],[30,4],[30,0,[\"onChange\"]],[30,0,[\"selected\"]],[30,0,[\"options\"]],true,[30,0,[\"onOpen\"]],[30,0,[\"search\"]],[28,[37,5],[[28,[37,6],[[30,0,[\"searchTerm\"]]],null]],null],[30,0,[\"resetSearch\"]],[52,[30,0,[\"searchTerm\"]],[52,[30,0,[\"loading\"]],\"Loading...\",\"No results found\"],[52,[30,0,[\"loading\"]],\"Loading...\",\"No options available\"]]]]]],[[[1,\"      \"],[18,6,[[28,[37,4],null,[[\"label\",\"select\"],[[30,3,[\"label\"]],[30,5]]]]]],[1,\"\\n\"]],[5]]],[1,\"  \"]],[3]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"@required\",\"selectGroup\",\"@searchField\",\"select\",\"&default\"],false,[\"ui/wrapper\",\"ui/input/select-group\",\"let\",\"component\",\"hash\",\"fn\",\"mut\",\"if\",\"yield\"]]",
    "moduleName": "itarp-components/components/ui/input/infinity-select.hbs",
    "isStrictMode": false
  });

  let UiInputInfinitySelectComponent = (_class = class UiInputInfinitySelectComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "initiallyLoaded", _descriptor, this);

      _initializerDefineProperty(this, "allOptionsLoaded", _descriptor2, this);

      _initializerDefineProperty(this, "loading", _descriptor3, this);

      _initializerDefineProperty(this, "searchResults", _descriptor4, this);

      _initializerDefineProperty(this, "_options", _descriptor5, this);

      _initializerDefineProperty(this, "searchTerm", _descriptor6, this);

      _initializerDefineProperty(this, "page", _descriptor7, this);

      _initializerDefineProperty(this, "totalPages", _descriptor8, this);

      _initializerDefineProperty(this, "store", _descriptor9, this);
    }

    get size() {
      return this.args.size ? this.args.size : 50;
    }

    get query() {
      const additionalParams = this.args.query || {};
      return { ...additionalParams,
        page: this.page,
        per_page: this.size
      };
    }

    get selected() {
      return this.args.selected;
    }

    get options() {
      return new Promise(resolve => {
        var _this$_options;

        if (this.searchTerm) {
          resolve(this.searchResults);
        }

        if ((_this$_options = this._options) !== null && _this$_options !== void 0 && _this$_options.length) resolve(this._options);
      });
    }

    resetSearch() {
      this.searchResults = [];
      this.searchTerm = null;
    }

    onChange() {
      if (this.args.onChange) this.args.onChange(...arguments);
    }

    async fetchOptions(query) {
      const model = this.args.modelName;
      if (!model) return;
      return this.store.query(model, query).then(data => {
        var _data$meta;

        const meta = data === null || data === void 0 ? void 0 : (_data$meta = data.meta) === null || _data$meta === void 0 ? void 0 : _data$meta.page;

        if (meta) {
          this.totalPages = Math.ceil(meta.total / meta.per_page);
        }

        return data.toArray();
      });
    }

    searchOptions(term) {
      const field = this.args.searchField;
      this.fetchOptions({ ...this.query,
        page: 1,
        advance_filter: {
          [field]: {
            $regex: term,
            $options: 'i'
          }
        }
      }).then(data => {
        this.searchResults = data;
        this.loading = false;
      });
    }

    search(term) {
      this.loading = true;
      (0, _runloop.debounce)(this, 'searchOptions', term, 500);
    }

    async onOpen() {
      if (this.initiallyLoaded) return;
      this._options = await this.fetchOptions(this.query);
      this.initiallyLoaded = true;
    }

    async loadMore() {
      if (this.loading || this.allOptionsLoaded) return;

      if (this.totalPages && this.totalPages <= this.page) {
        this.allOptionsLoaded = true;
        return;
      }

      this.loading = true;
      const options = await this._options;
      this.page += 1;
      this.fetchOptions(this.query).then(data => {
        this._options = [...this._options, ...data];
        this.loading = false;
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "initiallyLoaded", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "allOptionsLoaded", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "searchResults", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_options", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "searchTerm", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "page", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "totalPages", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "resetSearch", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "resetSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchOptions", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "fetchOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchOptions", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "searchOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "search", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onOpen", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onOpen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadMore", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadMore"), _class.prototype)), _class);
  _exports.default = UiInputInfinitySelectComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiInputInfinitySelectComponent);
});
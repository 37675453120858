define("itarp-opportunity-designer/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    this.route('browse', {
      path: ''
    });
    this.route('details', {
      path: '/:id'
    }, function () {
      this.route('overview', {
        path: ''
      });
      this.route('sourcing');
      this.route('unsuccessful');
      this.route('applied');
    });
    this.route('opportunity', function () {
      this.route('job-details');
      this.route('work-arrangement');
      this.route('application-requirement');
      this.route('scorecards');
      this.route('workflows');
    });
    this.route('edit-opportunity', {
      path: 'opportunity/:id'
    }, function () {
      this.route('job-details');
      this.route('work-arrangement');
      this.route('application-requirement');
      this.route('scorecards');
      this.route('workflows');
    });
    this.route('publishing-options', {
      path: 'opportunity/:id/publishing-options'
    }, function () {
      this.route('settings', {
        path: ''
      });
      this.route('free-boards');
    });
    this.route('my-rosters', {
      path: '/rosters'
    });
    this.route('shared-rosters');
    this.route('shared-rosters.show', {
      path: '/shared-rosters/:id'
    });
    this.route('chat', {
      path: '/messages'
    }, function () {
      this.route('messages', {
        path: '/:id'
      });
    });
    this.route('candidates');
    this.route('my-opportunities');
    this.route('browse-public', {
      path: '/experfy'
    });
    this.route('details-public', {
      path: '/experfy/:id'
    }, function () {
      this.route('overview', {
        path: ''
      });
    });
  });

  _exports.default = _default;
});